import React, { useState } from "react";
import "./QuestionHeader.scss";

export interface IProps {
  title: string;
  onMenuClick: any;
  handleStyleClick: any;
  newStyle: any;
}

export const QuestionHeader = (props: IProps) => {

  return (
    <div className={"question-header"} style={props.newStyle}>

      <div className={"question-title"}>
          <div>{props.title}</div>
      </div>
      {/*<div className="question-images">*/}
      {/*  /!* <img className="icon_roue" src={process.env.PUBLIC_URL + "/pictos/roue.svg"} alt={"Menu"} onClick={() => props.onMenuClick()} /> *!/*/}
      {/*  <img*/}
      {/*    className="icon_style"*/}
      {/*    src={`${process.env.PUBLIC_URL}${props.newStyle ? '/pictos/icon_decrease_font.png' : '/pictos/icon_increase_font.png'}`}*/}
      {/*    onClick={props.handleStyleClick} alt={"Btn change text size"}*/}
      {/*    style={{ opacity: `${props.newStyle ? 1 : 0.4}` }} />*/}
      {/*</div>*/}
    </div>
  );
}
