import "./Popup.scss";

interface PopupProps {
    closePopup: () => void;
}

const Popup = ({closePopup} : PopupProps ) => {
    const wiki =
        `<iframe width='100%' height='100%' scrolling='yes' src='/general-conditions' sandbox='allow-modals allow-forms allow-popups allow-scripts allow-same-origin'></iframe>`;

    return (
        <div className="popup" >
            <div className="popup-inner" style={{background:"white"}} dangerouslySetInnerHTML={{ __html: wiki ? wiki : "" }} />
            <img className="btn-close" src={process.env.PUBLIC_URL + "/pictos/icon_close.svg"} onClick={closePopup} alt={"Close"}/>
        </div>
    );
};

export default Popup;
