import {Question} from "../../../models/Question";
import "./QuestionNoResponseStyles.scss";
import { Constants } from "../../../utils/Constants";


interface IProps {
    question: Question;
}

export const QuestionNoResponseView = (props: IProps) => {
    const isBlindModeOn: string = localStorage.getItem(Constants.BLIND_MODE)!;
    let picture;

    if (props.question.picture !== undefined) { //&& isBlindModeOn === 'false'
        picture = (
            <div className={"question-picture"}>
                <img src={props.question.picture} alt={props.question.title}/>
            </div>
        )
    }

    return (
        <div className={"question-content question-no-response"}>
            {picture}
            <div className={"no-response"}>
              <div
                className={"lm-content"}
                dangerouslySetInnerHTML={{__html: props.question.answer?.title ?? ""}}
              />
            </div>
        </div>
    )
}
