import React, {Component, useState} from "react";
import "./Welcome.scss";
import { IProps as IFooterProps } from "../../components/question-footer/QuestionFooter";
import { Constants } from "../../utils/Constants";
import { LoadingView } from "../loading/LoadingView";
import { ServiceController } from "../../controllers/ServiceController";
import { Service } from "../../models/Service";
import FlagIcon from "../../components/flag/FlagComponent";
import { Language } from "../../models/Language";
import { TaxonomyController } from "../../controllers/TaxonomyController";
import { LanguageController } from "../../controllers/LanguageController";
import { compareStrings } from "../../utils/Utils";
import Popup from "../popup/Popup";

interface IState {
    services: Service[];
    selected?: Service;
    languages: { key: string; name: string; flagUrl?: string }[];
    showAllLanguages: boolean;
    selectedLanguage?: Language;
    isConsentChecked: any
    openPopup: boolean;
}

const translations: { [key: string]: any } = {
    "en": {
        "welcomeMessage": "Welcome to",
        "introMessage": "Your personal assistant to start explaining what brings you here.",
        "navigateArrows": "Navigate with arrows",
        "previousQuestion": "PREVIOUS QUESTION",
        "nextQuestion": "NEXT QUESTION",
        "helpMessage": "If you don't know the answer, simply click the button:",
        "consentMessage": "I consent to the collection and processing of personal data and I declare to accept the",
        "generalConditions": "general conditions"
    },
    "ge": {
        "welcomeMessage": "კეთილი იყოს თქვენი მობრძანება",
        "introMessage": "თქვენი პირადი ასისტენტი, რომელიც დაგეხმარებათ გაიგოთ აქ ყოფნის მიზეზი.",
        "navigateArrows": "ნავიგაცია ისრებით",
        "previousQuestion": "წინა კითხვა",
        "nextQuestion": "შემდეგი კითხვა",
        "helpMessage": "თუ პასუხი არ იცით, უბრალოდ დააჭირეთ ღილაკს:",
        "consentMessage": "ვეთანხმები პირადი მონაცემების შეგროვებასა და დამუშავებას და ვაცხადებ, რომ ვიღებ",
        "generalConditions": "ზოგად პირობებს"
    },
    "al": {
        "welcomeMessage": "Mirësevini te",
        "introMessage": "Asistenti juaj personal për të filluar të shpjegoni çfarë ju sjell këtu.",
        "navigateArrows": "Lëvizni me shigjeta",
        "previousQuestion": "PYETJA E MËPARSHME",
        "nextQuestion": "PYETJA TJETËR",
        "helpMessage": "Nëse nuk e dini përgjigjen, thjesht klikoni në butonin:",
        "consentMessage": "Pranoj mbledhjen dhe përpunimin e të dhënave personale dhe deklaroj se pranoj",
        "generalConditions": "kushtet e përgjithshme"
    },
    "fr": {
        "welcomeMessage": "Bienvenu.e.s sur",
        "introMessage": "Votre assistant personnel pour commencer à expliquer ce qui vous amène ici.",
        "navigateArrows": "Naviguez avec les flèches",
        "previousQuestion": "QUESTION PRÉCÉDENTE",
        "nextQuestion": "QUESTION SUIVANTE",
        "helpMessage": "Si vous ne savez pas répondre, cliquez simplement sur le bouton :",
        "consentMessage": "Je consens à la collecte et au traitement de données à caractère personnel et je déclare accepter les",
        "generalConditions": "conditions générales"
    },
    "es": {
        "welcomeMessage": "Bienvenido a",
        "introMessage": "Tu asistente personal para empezar a explicar lo que te trae aquí.",
        "navigateArrows": "Navega con flechas",
        "previousQuestion": "PREGUNTA ANTERIOR",
        "nextQuestion": "SIGUIENTE PREGUNTA",
        "helpMessage": "Si no sabes la respuesta, simplemente haz clic en el botón:",
        "consentMessage": "Consiento la recogida y el tratamiento de datos personales y declaro aceptar las",
        "generalConditions": "condiciones generales"
    },
    "de": {
        "welcomeMessage": "Willkommen bei",
        "introMessage": "Ihr persönlicher Assistent, um zu erklären, was Sie hierher bringt.",
        "navigateArrows": "Navigieren Sie mit Pfeilen",
        "previousQuestion": "VORHERIGE FRAGE",
        "nextQuestion": "NÄCHSTE FRAGE",
        "helpMessage": "Wenn Sie die Antwort nicht kennen, klicken Sie einfach auf die Schaltfläche:",
        "consentMessage": "Ich stimme der Erhebung und Verarbeitung personenbezogener Daten zu und erkläre, die",
        "generalConditions": "Allgemeinen Geschäftsbedingungen zu akzeptieren"
    },
    "it": {
        "welcomeMessage": "Benvenuti a",
        "introMessage": "Il tuo assistente personale per iniziare a spiegare cosa ti porta qui.",
        "navigateArrows": "Naviga con le frecce",
        "previousQuestion": "DOMANDA PRECEDENTE",
        "nextQuestion": "PROSSIMA DOMANDA",
        "helpMessage": "Se non conosci la risposta, clicca semplicemente sul pulsante:",
        "consentMessage": "Acconsento alla raccolta e al trattamento dei dati personali e dichiaro di accettare le",
        "generalConditions": "condizioni generali"
    },
    "pt": {
        "welcomeMessage": "Bem-vindo a",
        "introMessage": "Seu assistente pessoal para começar a explicar o que o traz aqui.",
        "navigateArrows": "Navegue com as setas",
        "previousQuestion": "QUESTÃO ANTERIOR",
        "nextQuestion": "PRÓXIMA QUESTÃO",
        "helpMessage": "Se você não souber a resposta, clique no botão:",
        "consentMessage": "Consinto a coleta e o processamento de dados pessoais e declaro aceitar os",
        "generalConditions": "termos e condições gerais"
    },
    "pt-pt": {
        "welcomeMessage": "Bem-vindo a",
        "introMessage": "Seu assistente pessoal para começar a explicar o que o traz aqui.",
        "navigateArrows": "Navegue com as setas",
        "previousQuestion": "QUESTÃO ANTERIOR",
        "nextQuestion": "PRÓXIMA QUESTÃO",
        "helpMessage": "Se você não souber a resposta, clique no botão:",
        "consentMessage": "Consinto a coleta e o processamento de dados pessoais e declaro aceitar os",
        "generalConditions": "termos e condições gerais"
    },
    "zh": {
        "welcomeMessage": "欢迎来到",
        "introMessage": "您的个人助手，开始解释带您来这里的原因。",
        "navigateArrows": "用箭头导航",
        "previousQuestion": "上一个问题",
        "nextQuestion": "下一个问题",
        "helpMessage": "如果您不知道答案，只需点击按钮：",
        "consentMessage": "我同意收集和处理个人数据，并声明接受",
        "generalConditions": "一般条款和条件"
    },
    "ja": {
        "welcomeMessage": "ようこそ",
        "introMessage": "ここに来た理由を説明するためのあなたの個人的なアシスタント。",
        "navigateArrows": "矢印で移動します",
        "previousQuestion": "前の質問",
        "nextQuestion": "次の質問",
        "helpMessage": "答えがわからない場合は、ボタンをクリックしてください：",
        "consentMessage": "個人データの収集と処理に同意し、以下を受け入れることを宣言します",
        "generalConditions": "一般条件"
    },
    "ko": {
        "welcomeMessage": "환영합니다",
        "introMessage": "여기 오신 이유를 설명하기 위한 개인 비서입니다.",
        "navigateArrows": "화살표로 탐색",
        "previousQuestion": "이전 질문",
        "nextQuestion": "다음 질문",
        "helpMessage": "답을 모르면 버튼을 클릭하세요:",
        "consentMessage": "개인 데이터 수집 및 처리에 동의하며, 다음을 수락합니다",
        "generalConditions": "일반 조건"
    },
    "ar": {
        "welcomeMessage": "مرحبًا بك في",
        "introMessage": "مساعدك الشخصي لبدء شرح ما يجلبك هنا.",
        "navigateArrows": "تنقل باستخدام الأسهم",
        "previousQuestion": "السؤال السابق",
        "nextQuestion": "السؤال التالي",
        "helpMessage": "إذا كنت لا تعرف الإجابة، فقط اضغط على الزر:",
        "consentMessage": "أوافق على جمع ومعالجة البيانات الشخصية وأقر بقبول",
        "generalConditions": "الشروط العامة"
    },

    "s": {
        "welcomeMessage": "مرحبًا بك في",
        "introMessage": "مساعدك الشخصي لبدء شرح ما يجلبك هنا.",
        "navigateArrows": "تنقل باستخدام الأسهم",
        "previousQuestion": "السؤال السابق",
        "nextQuestion": "السؤال التالي",
        "helpMessage": "إذا كنت لا تعرف الإجابة، فقط اضغط على الزر:",
        "consentMessage": "أوافق على جمع ومعالجة البيانات الشخصية وأقر بقبول",
        "generalConditions": "الشروط العامة"
    },
    "tn": {
        "welcomeMessage": "مرحبا بيك في",
        "introMessage": "مساعدك الشخصي باش يفسر علاش جيت لهنا.",
        "navigateArrows": "تنقل بالسهام",
        "previousQuestion": "السؤال اللي فات",
        "nextQuestion": "السؤال الجاي",
        "helpMessage": "إذا ماكش عارف الإجابة، برك على الزر:",
        "consentMessage": "نوافق على جمع ومعالجة البيانات الشخصية ونصرح بقبول",
        "generalConditions": "الشروط العامة"
    },
    "ru": {
        "welcomeMessage": "Добро пожаловать в",
        "introMessage": "Ваш личный помощник, чтобы объяснить, что привело вас сюда.",
        "navigateArrows": "Навигация с помощью стрелок",
        "previousQuestion": "ПРЕДЫДУЩИЙ ВОПРОС",
        "nextQuestion": "СЛЕДУЮЩИЙ ВОПРОС",
        "helpMessage": "Если вы не знаете ответа, просто нажмите на кнопку:",
        "consentMessage": "Я согласен на сбор и обработку персональных данных и подтверждаю, что принимаю",
        "generalConditions": "общие условия"
    },
    "tr": {
        "welcomeMessage": "Hoş geldiniz",
        "introMessage": "Buraya sizi getiren şeyi açıklamak için kişisel asistanınız.",
        "navigateArrows": "Oklarla gezin",
        "previousQuestion": "ÖNCEKİ SORU",
        "nextQuestion": "SONRAKİ SORU",
        "helpMessage": "Eğer cevabı bilmiyorsanız, sadece butona tıklayın:",
        "consentMessage": "Kişisel verilerin toplanmasını ve işlenmesini kabul ediyorum ve",
        "generalConditions": "genel şartları kabul ettiğimi beyan ediyorum"
    },
    "ro": {
        "welcomeMessage": "Bine ai venit la",
        "introMessage": "Asistentul tău personal pentru a începe să explici ce te aduce aici.",
        "navigateArrows": "Navighează cu săgețile",
        "previousQuestion": "ÎNTREBAREA ANTERIOARĂ",
        "nextQuestion": "ÎNTREBAREA URMĂTOARE",
        "helpMessage": "Dacă nu știi răspunsul, apasă pur și simplu pe buton:",
        "consentMessage": "Îmi dau consimțământul pentru colectarea și procesarea datelor personale și declar că accept",
        "generalConditions": "termenii și condițiile generale"
    }


}


export class Welcome extends Component<{}, IState> {
    serviceController = new ServiceController();
    taxonomyController = new TaxonomyController();
    languageController = new LanguageController();
    selectedLanguageKey = localStorage.getItem(Constants.KEY_LANGUAGE) || "en";

    constructor(props: {}) {
        super(props);
        this.state = {
            services: [],
            languages: [],
            showAllLanguages: false,
            selectedLanguage: undefined,
            isConsentChecked: false,
            openPopup: false
        };
    }


    componentDidMount() {
        // Resetting localStorage values
        localStorage.removeItem(Constants.KEY_ANSWERS);
        //localStorage.removeItem(Constants.KEY_START);
        //localStorage.removeItem(Constants.KEY_CATEGORY);
        localStorage.removeItem(Constants.BLIND_MODE);

        const formattedDate = new Date().toLocaleString('fr-FR', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            timeZone: 'Europe/Paris' // Ensure the correct time zone
        });

        localStorage.setItem(Constants.KEY_START, formattedDate);

        // Fetching services and languages
        this.serviceController.getServices().then((res) => {
            this.setState({ services: res });
        });

        Promise.all([this.taxonomyController.getFlags(), this.languageController.get()]).then(res => {
            let flags: any = res[0];
            let languages = Object.keys(res[1].data.languages).map(key => new Language(key, res[1].data.languages[key]));

            flags.forEach((flag: any) => {
                let language = languages.find(l => l.key === flag.key);
                if (language) {
                    language.flagUrl = flag.value;
                }
            });

            // Set French as the default language if it exists
            // const defaultLanguage = languages.find(lang => lang.key === "fr") || languages[0];
            // this.setState({
            //     languages,
            //     selectedLanguage: defaultLanguage
            // });
            //
            // if (defaultLanguage) {
            //     localStorage.setItem(Constants.KEY_LANGUAGE, defaultLanguage.key);
            //     localStorage.setItem(Constants.NAME_LANGUAGE, defaultLanguage.name);
            //     localStorage.setItem(Constants.KEY_START, new Date().toLocaleString());
            // }
        });

        // if (!localStorage.getItem(Constants.KEY_FIRST_QUESTION)) {
        //     let firstQuestionId = "5ce09476-b23a-404a-859e-17c5bea91080";
        //     this.serviceController.getServices().then(res => {
        //         if (res) {
        //             let service = res.find((s: any) => compareStrings(window.location.pathname, s.name));
        //             if (service) {
        //                 firstQuestionId = service.firstQuestionId;
        //             }
        //         }
        //         localStorage.setItem(Constants.KEY_FIRST_QUESTION, firstQuestionId);
        //     })
        // }
    }
    handleBackNavigation = () => {
        const serviceSelected = localStorage.getItem("SERVICE_SELECTED");
        if (serviceSelected) {
            const service = JSON.parse(serviceSelected);
            if (service && service.name) {
                window.location.href = `/`;
            }
        }
    }

    handleNextNavigation = () => {

        if (!this.state.isConsentChecked) {
            alert("Veuillez accepter les conditions avant de continuer.");
            return;
        }

        const firstQuestionId = localStorage.getItem(Constants.KEY_FIRST_QUESTION);
        if (firstQuestionId) {
            window.location.href = `/question/${firstQuestionId}`;
        }
        else {
            window.location.href = `/`;
        }
    }

    handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ isConsentChecked: event.target.checked });
    }



    handlerOpenPopup = () => {
        this.setState((prevState: any) => ({ openPopup: !prevState.openPopup }));
    };

    render() {
        const { openPopup } = this.state;
        const translation = translations[this.selectedLanguageKey];
        return (
            <div className="page">
                <div className="welcome">

                    {/* Welcome message */}
                    <h1>{translation.welcomeMessage}</h1>
                    <img src={process.env.PUBLIC_URL + "/logo-couleur.jpg"} alt="Marti logo" style={{ width: 60, margin: "10px auto" }} />
                    <p>{translation.introMessage}</p>

                    {/* Navigation Instructions */}
                    <div className="navigation-instructions">
                        <h3>{translation.navigateArrows}</h3>
                        <div className="navigation-buttons">
                            <button className="nav-button">←</button>
                            <div>{translation.previousQuestion}</div>
                            <button className="nav-button">→</button>
                            <div>{translation.nextQuestion}</div>
                        </div>
                    </div>

                    {/* Help message */}
                    <div className="help-message">
                        <p>{translation.helpMessage}</p>

                        <div className={'btn-idk'}>
                            <img src={process.env.PUBLIC_URL + "/pictos/shrug.png"} alt="Help icon" style={{ width: 60 }} />
                        </div>
                    </div>

                    {/* Consent checkbox */}
                    <div className="consent-checkbox">
                        <input type="checkbox" id="consent" checked={this.state.isConsentChecked}
                               onChange={this.handleCheckboxChange} />
                        <label htmlFor="consent">
                            {translation.consentMessage}<a  onClick={(e) => {
                            e.preventDefault(); // Prevent the default navigation behavior
                            this.handlerOpenPopup(); // Open the popup
                        }} href="/general-conditions"> {translation.generalConditions}</a>.
                        </label>
                    </div>

                    {/* Footer navigation arrows */}
                    <div className="footer-navigation">
                        <button className="footer-nav-button" onClick={this.handleBackNavigation}>←</button>
                        <button className="footer-nav-button" onClick={this.handleNextNavigation}>→</button>
                    </div>
                </div>
                {openPopup && <Popup closePopup={this.handlerOpenPopup}></Popup>}
            </div>

        );
    }

}
