export const dataTraduction = [
    {
        key: "fr",
        name: "Français",
        data: {
            RGPD: ["En répondant à ce questionnaire, je consens à la collecte et au traitement de données à caractère personnel relatives à la santé de mon enfant."],
            CGU: ["En cochant cette case, je déclare avoir pris connaissance des conditions générales d'utilisation de l'outil et les accepter."],
            checkboxMultiValued: ["Plusieurs réponses possibles"],
            placeholder: ["Merci d’écrire votre réponse ici"],
            help: ["Merci de saisir votre réponse ici"],
            yes: 'Oui',
            no: 'Non'
        }
    },
    {
        key: "en",
        name: "English",
        data: {
            RGPD: ["By completing this questionnaire, I consent to the collection and processing of my child's personal health information."],
            CGU: ["By checking this box, I declare that I have read the general conditions of use of the tool and accept them."],
            checkboxMultiValued: ["Several answers are possible"],
            placeholder: ["Please write your answer here"],
            help: ["Please answer the question simply and do not enter any information that could identify your child (first name, surname, etc.)."],

            yes: 'Yes',
            no: 'No'
        }
    },
    {
        key: "es",
        name: "Español",
        data: {
            RGPD: ["Al rellenar este cuestionario, doy mi consentimiento para que se recojan y traten los datos personales relativos a la salud de mi hijo."],
            CGU: ["Al marcar esta casilla, declaro que he leído las condiciones generales de uso de la herramienta y las acepto."],
            checkboxMultiValued: ["Varios respuestas son posibles"],
            placeholder: ["Porfavor escriba su respuesta aqui"],
            help: ["Responda a la pregunta de forma sencilla y no incluya ningún dato que pueda identificar a su hijo (nombre, apellidos, etc.)."],

            yes: 'Sí',
            no: 'No'
        }
    },
    {
        key: "it",
        name: "Italiano",
        data: {
            RGPD: ["Compilando questo questionario, acconsento alla raccolta e al trattamento dei dati personali relativi alla salute di mio figlio."],
            CGU: ["Selezionando questa casella, dichiaro di aver letto le condizioni generali di utilizzo dello strumento e di accettarle."],
            checkboxMultiValued: ["Molte risposte sono possibili"],
            placeholder: ["Per favore scrivi qui la tua risposta"],
            help: ["Si prega di rispondere alla domanda in modo semplice e di non includere informazioni che possano identificare il bambino (nome, cognome, ecc.)."],

            yes: 'Sì',
            no: 'No'
        }
    },
    {
        key: "dz",
        name: "اللغة العربية الأدبية (arabe littéraire)",
        data: {
            RGPD: ["من خلال الإجابة على هذا الاستبيان ، أوافق على جمع ومعالجة البيانات الشخصية المتعلقة بصحة طفلي"],
            CGU: ["بتحديد هذا المربع ، أقر بأنني قد قرأت الشروط العامة لاستخدام الأداة وأوافق عليها."],
            checkboxMultiValued: ["العديد من الإجابات ممكنة"],
            placeholder: ["الرجاء كتابة إجابتك هنا"],
            yes: 'ا',
            help: ["يرجى الإجابة ببساطة على السؤال وعدم كتابة أي بيانات هنا يمكن أن تحدد هوية طفلك (الاسم الأول، اسم العائلة، وما إلى ذلك)"],

            no: 'نعم'
        }
    },
    {
        key: "ara",
        name: "3arabi",
        data: {
            RGPD: ["من خلال الإجابة على هذا الاستبيان ، أوافق على جمع ومعالجة البيانات الشخصية المتعلقة بصحة طفلي"],
            CGU: ["بتحديد هذا المربع ، أقر بأنني قد قرأت الشروط العامة لاستخدام الأداة وأوافق عليها."],
            checkboxMultiValued: ["العديد من الإجابات ممكنة"],
            placeholder: ["الرجاء كتابة إجابتك هنا"],
            help: ["يرجى الإجابة ببساطة على السؤال وعدم كتابة أي بيانات هنا يمكن أن تحدد هوية طفلك (الاسم الأول، اسم العائلة، وما إلى ذلك)"],

            yes: 'ا',
            no: 'نعم'
        }
    },
    {
        key: "al",
        name: "Shqiptare",
        data: {
            RGPD: ["Duke iu përgjigjur këtij pyetësori, unë pranoj mbledhjen dhe përpunimin e të dhënave personale në lidhje me shëndetin e fëmijës tim."],
            CGU: ["Duke zgjedhur këtë kuti, unë deklaroj se i kam lexuar kushtet e përgjithshme të përdorimit të mjetit dhe i pranoj ato."],
            checkboxMultiValued: ["Shumë përgjigje janë të mundshme"],
            placeholder: ["Ju lutem shkruani përgjigjen tuaj këtu"],
            help: ["Ju lutemi thjesht përgjigjuni pyetjes dhe mos shkruani asnjë të dhënë këtu që mund të identifikojë fëmijën tuaj (emri, mbiemri, etj.)"],

            yes: 'Po',
            no: 'Jo'
        }
    },
    {
        key: "pt-pt",
        name: "Português",
        data: {
            RGPD: ["Ao preencher este questionário, autorizo a recolha e o tratamento de dados pessoais relacionados com a saúde do meu filho."],
            CGU: ["Ao marcar esta caixa, declaro que li as condições gerais de uso da ferramenta e as aceito."],
            checkboxMultiValued: ["Muitas respostas são possíveis"],
            placeholder: ["Por favor, escreva sua resposta aqui"],
            help: ["Responda à pergunta de forma simples e não inclua qualquer informação que possa identificar o seu filho (nome próprio, apelido, etc.)."],

            yes: 'Sim',
            no: 'Não'
        }
    },
    {
        key: "ge",
        name: "ქართველი (kartveli)",
        data: {
            RGPD: ["ამ კითხვარზე პასუხის გაცემით მე თანახმა ვარ ჩემი შვილის ჯანმრთელობასთან დაკავშირებული პერსონალური მონაცემების შეგროვებასა და დამუშავებაზე."],
            CGU: ["ამ ველის მონიშვნით ვაცხადებ, რომ წავიკითხე ინსტრუმენტის გამოყენების ზოგადი პირობები და ვეთანხმები მათ."],
            checkboxMultiValued: ["ბევრი პასუხია შესაძლებელი"],
            placeholder: ["გთხოვთ დაწეროთ თქვენი პასუხი აქ"],
            help: ["გთხოვთ, უბრალოდ უპასუხეთ კითხვას და არ დაწეროთ აქ მონაცემები, რომლითაც შეიძლება თქვენი შვილის იდენტიფიცირება (სახელი, გვარი და ა.შ.)"],

            yes: 'კი',
            no: 'არა'
        }
    },
    {
        key: "ro",
        name: "Română",
        data: {
            RGPD: ["Prin completarea acestui chestionar, sunt de acord cu colectarea și prelucrarea datelor cu caracter personal referitoare la sănătatea copilului meu."],
            CGU: ["Bifând această casetă, declar că am citit condițiile generale de utilizare a instrumentului și le accept."],
            checkboxMultiValued: ["Sunt posibile multe răspunsuri"],
            placeholder: ["Te rog scrie raspunsul tau aici"],
            help: ["Vă rugăm să răspundeți simplu la întrebare și să nu includeți nicio informație care ar putea să vă identifice copilul (nume, prenume etc.)."],

            yes: 'Da',
            no: 'Nu'
        }
    },
    {
        key: "ru",
        name: "русский (russkiy)",
        data: {
            RGPD: ["Заполняя эту анкету, я даю согласие на сбор и обработку персональных данных, касающихся здоровья моего ребенка."],
            CGU: ["Установив этот флажок, я заявляю, что ознакомился с общими условиями использования инструмента и принимаю их."],
            checkboxMultiValued: ["Можно много ответов"],
            placeholder: ["Пожалуйста, напишите свой ответ здесь"],
            help: ["Пожалуйста, отвечайте на вопрос просто и не указывайте никакой информации, которая может идентифицировать вашего ребенка (имя, фамилия и т.д.)."],

            yes: 'да',
            no: 'нет'
        }
    },
    {
        key: "tr",
        name: "Türk",
        data: {
            RGPD: ["Bu anketi doldurarak çocuğumun sağlığıyla ilgili kişisel verilerin toplanmasına ve işlenmesine izin veriyorum."],
            CGU: ["Bu kutucuğu işaretleyerek, aracın genel kullanım koşullarını okuduğumu ve kabul ettiğimi beyan ederim."],
            checkboxMultiValued: ["Birçok cevap mümkün"],
            placeholder: ["Lütfen cevabınızı buraya yazınız"],
            help: ["Lütfen soruyu basitçe yanıtlayın ve çocuğunuzu tanımlayabilecek herhangi bir bilgi (ad, soyad, vb.) eklemeyin."],
            yes: 'Evet',
            no: 'Hayır'
        }
    }
]
