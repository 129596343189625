import { Question } from "../../../models/Question";
import "./QuestionCheckboxesStyles.scss"
import { OpenAnswer } from "../../../components/OpenAnswer";
import { Constants } from "../../../utils/Constants";
import { dataTraduction } from "../../../utils/DataTraduction";

interface IProps {
  question: Question;
  onOptionClick: any;
}


export const QuestionCheckboxesView = (props: IProps) => {
  const isBlindModeOn: string = localStorage.getItem(Constants.BLIND_MODE)!;
  let picture;
  let multiValuedText;

  if (props.question.picture ) { // && isBlindModeOn === 'false'
    picture = (
      <div className={"question-picture"}>
        <img src={props.question.picture} alt={props.question.title} />
      </div>
    )
  }

  let checkboxes = [];
  if (props.question.answer?.options) {
    for (let option of props.question.answer.options) {
      let active = props.question.answer.value === option.id ||
        (Array.isArray(props.question.answer.value) && (props.question.answer.value as string[]).includes(option.id));
      let picture = option.picture ?
        <div className={option.title ? "with-title" : ""}>
          <img src={option.picture} alt={option.title} />
        </div> :
          option.id === "other" ?
              <OpenAnswer /> : null;

      let title = (
        <span>
          {option.title}
        </span>
      );

      if (option.id === "other") {
        title = (
          <span>
            {option.title ? option.title : localStorage.getItem(Constants.KEY_TRANSLATE_BUTTON_TEXT)}
          </span>
        );
      }

      if (picture) { //  && isBlindModeOn === 'false'
        checkboxes.push(
          <div key={option.id} className={"checkbox"}>
            <button key={option.id} className={active ? "active" : ""}
              onClick={() => props.onOptionClick(option.id)}>
              {picture}
            </button>
            <p>{title}</p>
          </div>
        )
      } else {
        checkboxes.push(
          <div key={option.id} className={"checkbox"}>
            <button key={option.id} className={active ? "active" : ""}
              onClick={() => props.onOptionClick(option.id)}>
              {title}
            </button>
          </div>
        )
      }

      if (props.question.answer?.multiValued) {
        multiValuedText = <div className={"question-checkboxes-title"}>
          {dataTraduction.find(l => l.key === localStorage.getItem(Constants.KEY_LANGUAGE))?.data.checkboxMultiValued[0]}
        </div>
      }
    }
  }

  return (
    <div className={"question-content question-checkboxes"}>
      {picture}
      {multiValuedText}
      <div className={"checkboxes"}>
        {checkboxes}
      </div>
    </div>
  )
}
